import { JSXElementConstructor } from "react";
import { cx } from "../classnames";

export type TagColor =
	| "graphite"
	| "red"
	| "orange"
	| "green"
	| "brand"
	| "purple"
	| "pink";

export interface TagProps {
	text: string;
	size?: "sm" | "md" | "lg";
	color?: TagColor;
	leftIcon?: JSXElementConstructor<{ className?: string }>;
	rightIcon?: JSXElementConstructor<{ className?: string }>;
	capitalize?: boolean;
	maxWidth?: number;
}

export const Tag: React.FC<TagProps> = ({
	text,
	size = "md",
	color = "graphite",
	leftIcon: LeftIcon,
	rightIcon: RightIcon,
	capitalize = false,
}) => {
	const textClassName = [
		(size === "sm" || size === "md") && "text-paragraph-xs",
		size === "lg" && "text-paragraph-sm",
		color === "graphite" ? "text-text-primary" : "",
		color === "red" ? "text-text-error " : "",
		color === "orange" ? "text-text-orange" : "",
		color === "green" ? "text-text-success " : "",
		color === "brand" ? "text-brand " : "",
		color === "purple" ? "text-purple-8 " : "",
		color === "pink" ? "text-pink-8" : "",
	].join(" ");

	const backgroundClassName = [
		(size === "sm" || size === "md") && "text-paragraph-xs",
		size === "lg" && "text-paragraph-sm",
		color === "graphite" ? "text-text-primary bg-sky-alpha-3" : "",
		color === "red" ? "text-text-error bg-surface-error" : "",
		color === "orange" ? "text-text-warning bg-surface-warning" : "",
		color === "green" ? "text-text-success bg-surface-success" : "",
		color === "brand" ? "text-brand bg-sky-alpha-2" : "",
		color === "purple" ? "text-purple-8 bg-purple-alpha-2" : "",
		color === "pink" ? "text-pink-8 bg-pink-alpha-2" : "",
	].join(" ");

	return (
		<div
			className={`${backgroundClassName} ${cx(
				"font-repro flex w-fit items-center gap-2 rounded-full",
				size === "sm" && "h-[18px] px-[6px]",
				size === "md" && "h-[22px] px-2",
				size === "lg" && "h-[28px] px-[10px]",
				capitalize && "capitalize",
			)}`}
		>
			{LeftIcon ? (
				<LeftIcon
					className={cx("h-[14px] w-[14px]", size === "sm" && "h-3 w-3")}
				/>
			) : null}
			<p className={`${textClassName} max-w-40 truncate`}>{text}</p>
			{RightIcon ? (
				<RightIcon
					className={cx("h-[14px] w-[14px]", size === "sm" && "h-3 w-3")}
				/>
			) : null}
		</div>
	);
};
