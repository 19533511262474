export * from "./errors";

export const AUTH_TOKEN_KEY = "july_auth_token";
export const ADMIN_AUTH_TOKEN_KEY = "july_admin_auth_token";
export const CREATOR_PROFILE_UUID_KEY = "july_creator_profile_uuid";
export const AGENCY_PUBLIC_ID = "july_agency_public_id";
export const DISABLE_DELETE_CONTENT_TAG_MODAL =
	"july_disable_delete_content_tag_modal";
export const STATS_TIME_PERIOD = "july_stats_time_period";
export const DISABLE_PAYMENTS_BANNER = "july_disable_payments_banner";
