import { z } from "zod";

export const PaymentStatus = z.enum(["unreviewed", "reviewed", "paid"]);
export type PaymentStatus = z.infer<typeof PaymentStatus>;

export const PaymentNetwork = z.enum(["ach", "wire", "card"]);
export type PaymentNetwork = z.infer<typeof PaymentNetwork>;

export const BankAccountType = z.enum(["individual", "company"]);
export type BankAccountType = z.infer<typeof BankAccountType>;

// Used within the payments table to show agencies the minimal amount of information that they
// need to decide what action they'd like to take on the payment.
export const PaymentPreviewSchema = z.object({
	uuid: z.string().uuid(),
	from: z.string(),
	receivedAt: z.date(),
	status: PaymentStatus,
	talent: z
		.object({
			name: z.string(),
			profilePictureUrl: z.string().url().optional(),
		})
		.array(),
	manager: z
		.object({
			name: z.string(),
			profilePictureUrl: z.string().url().optional(),
		})
		.array(),
	amount: z.number(),
});
export type PaymentPreview = z.infer<typeof PaymentPreviewSchema>;

// Used within the agency payments overview to show the agency what the payout amounts will be for
// the next time they get automatically paid out or initiate a manual payout.
export const NextPayoutInfoSchema = z.object({
	totalAmount: z.number(),
	creatorAmount: z.number(),
	agencyAmount: z.number(),
});
export type NextPayoutInfo = z.infer<typeof NextPayoutInfoSchema>;

// Used within the agency payments overview to show the overall revenue of the agency through July
// as well as their revenue over a given time span.
export const AgencyRevenueInfoSchema = z.object({
	total: z.number(),
	totalInTimeSpan: z.number(),
});
export type AgencyRevenueInfo = z.infer<typeof AgencyRevenueInfoSchema>;

export const AgencyPaymentsOverviewTimePeriod = z.enum([
	"Today",
	"Last 7 days",
	"Last 4 weeks",
	"Last 3 months",
	"Last 6 months",
	"Last 12 months",
	"Month to date",
	"Quarter to date",
	"Year to date",
	"All time",
]);
export type AgencyPaymentsOverviewTimePeriod = z.infer<
	typeof AgencyPaymentsOverviewTimePeriod
>;

// Used to get overall agency revenue and payout info for the header on the agency payments page.
// If numMonths is undefined we show all time revenue info.
export const AgencyPaymentsOverviewQuerySchema = z.object({
	numMonths: AgencyPaymentsOverviewTimePeriod.optional(),
});

// Data returned from the payments.getAgencyOverview endpoint.
export const AgencyPaymentsOverviewSchema = z.object({
	revenue: AgencyRevenueInfoSchema,
	nextPayout: NextPayoutInfoSchema,
});
export type AgencyPaymentsOverview = z.infer<
	typeof AgencyPaymentsOverviewSchema
>;

// Account info shown to the user for them to use in invoices
export const BankAccountInfoSchema = z.object({
	accountNumber: z.string(),
	routingNumber: z.string(),
	name: z.string(),
});
export type BankAccountInfo = z.infer<typeof BankAccountInfoSchema>;

// A talent manager entry for a payment split
export const PaymentSplitEntrySchema = z.object({
	uuid: z.string().uuid(),
	amount: z.number(),
	commission: z.number(),
	talent: z.object({
		uuid: z.string().uuid(),
		firstName: z.string(),
		lastName: z.string(),
		profilePictureUrl: z.string().optional(),
	}),
	manager: z
		.object({
			uuid: z.string().uuid(),
			firstName: z.string(),
			lastName: z.string(),
			profilePictureUrl: z.string().optional(),
		})
		.optional(),
});
export type PaymentSplitEntry = z.infer<typeof PaymentSplitEntrySchema>;

// An incoming payment and any associated splits
export const IncomingPaymentSchema = z.object({
	uuid: z.string().uuid(),
	from: z.string(),
	receivedAt: z.date(),
	amount: z.number(),
	status: PaymentStatus,
	network: PaymentNetwork,
	splitEntries: PaymentSplitEntrySchema.array(),
});
export type IncomingPayment = z.infer<typeof IncomingPaymentSchema>;

export const PayoutDetailsPreviewSchema = z
	.object({
		accountNumber: z.string(),
		accountNumberToken: z.string(),
		routingNumber: z.string(),
		routingNumberToken: z.string(),
		accountType: BankAccountType,
		nameOnAccount: z.string(),
	})
	.nullable();
export type PayoutDetailsPreview = z.infer<typeof PayoutDetailsPreviewSchema>;

export const PayoutDetailsMetadataSchema = z.object({
	creatorUuid: z.string().uuid(),
	updatedAt: z.date(),
});
export type PayoutDetailsMetadata = z.infer<typeof PayoutDetailsMetadataSchema>;

export const SetPayoutDetailsSchema = z.object({
	accountNumber: z.string(),
	routingNumber: z.string(),
	nameOnAccount: z.string(),
	accountType: BankAccountType,
});
export type SetPayoutDetails = z.infer<typeof SetPayoutDetailsSchema>;

export const SetTalentPayoutDetailsSchema = z.object({
	details: SetPayoutDetailsSchema,
	creatorProfileUuid: z.string().uuid(),
});
export type SetTalentPayoutDetailsSchema = z.infer<
	typeof SetTalentPayoutDetailsSchema
>;

export const StrictSetTalentPayoutDetailsSchema =
	SetTalentPayoutDetailsSchema.extend({
		details: z.object({
			accountNumber: z
				.string()
				.min(4, "Account number must contain at least 4 digits."),
			routingNumber: z
				.string()
				.length(9, "Routing number must be exactly 9 digits."),
			nameOnAccount: z
				.string()
				.min(2, "Account name must contain at least 2 characters."),
			accountType: BankAccountType,
		}),
	});
export type StrictSetTalentPayoutDetails = z.infer<
	typeof StrictSetTalentPayoutDetailsSchema
>;

export const StripePaymentStatus = z.enum([
	"processing",
	"failed",
	"canceled",
	"posted",
	"returned",
]);
export type StripePaymentStatus = z.infer<typeof StripePaymentStatus>;

export const StripeOutboundPaymentResponse = z.object({
	id: z.string(),
	status: StripePaymentStatus,
});
export type StripeOutbountPaymentResponse = z.infer<
	typeof StripeOutboundPaymentResponse
>;

export const StripeOutboundPaymentMetaData = z.object({
	feeForPayment: z.string().uuid().optional(),
});

export const PayoutPreviewSchema = z.object({
	uuid: z.string().uuid(),
	paidOutAt: z.date(),
	to: z.string(),
	forPayment: z.string().optional(),
	amount: z.number(),
	status: StripePaymentStatus,
});
export type PayoutPreview = z.infer<typeof PayoutPreviewSchema>;

export const PayoutDetailsSchema = z.object({
	to: z.string(),
	amount: z.number(),
	forPayout: z.string().optional(),
	accountNumberLastFour: z.string().optional(),
	postedAt: z.date().optional(),
	accountType: BankAccountType.optional(),
	fee: z.number().optional(),
	accountLastFour: z.string().optional(),
	status: StripePaymentStatus,
});
export type PayoutDetails = z.infer<typeof PayoutDetailsSchema>;

export const AgencyFastPayBankDetails = z.object({
	bankName: z.string(),
	accountNumber: z.string(),
	routingNumber: z.string(),
	bankAddress: z.string(),
});
export type AgencyFastPayBankDetails = z.infer<typeof AgencyFastPayBankDetails>;
