import { motion } from "framer-motion";
import { HTMLAttributes } from "react";

interface AnimateHeightProps extends HTMLAttributes<HTMLDivElement> {
	key: string;
	duration: number;
}

export const AnimateHeight = ({ duration, children }: AnimateHeightProps) => (
	<motion.div
		initial={{ height: 0 }}
		animate={{
			height: "auto",
			transition: { duration },
		}}
		exit={{
			height: 0,
			transition: { duration },
		}}
	>
		{children}
	</motion.div>
);
