import { z } from "zod";
import { ApiErrorSchema } from ".";

export const PostPickerErrorSchema = ApiErrorSchema.extend({
	name: z.enum([
		"YOUTUBE_NOT_CONNECTED",
		"TIKTOK_NOT_CONNECTED",
		"INSTAGRAM_NOT_CONNECTED",
		"FACEBOOK_NOT_CONNECTED",
		"INVALID_POST_URL",
		"INSTAGRAM_POST_NOT_FOUND",
		"NO_INSTAGRAM_THUMBNAIL",
	]),
});
export type PostPickerError = z.infer<typeof PostPickerErrorSchema>;

export const PostNotFound: PostPickerError = {
	name: "INVALID_POST_URL",
	httpCode: 400,
	message: "The post URL is invalid",
	description: "Please check the URL and try again.",
	type: "api",
};

export const YouTubeNotConnected: PostPickerError = {
	name: "YOUTUBE_NOT_CONNECTED",
	httpCode: 422,
	message: "This creator does not have their YouTube connected",
	description:
		"Please have the creator connect their YouTube account to July and try again",
	type: "api",
};

export const TikTokNotConnected: PostPickerError = {
	name: "TIKTOK_NOT_CONNECTED",
	httpCode: 422,
	message: "This creator does not have their TikTok connected",
	description:
		"Please have the creator connect their TikTok account to July and try again",
	type: "api",
};

export const InstagramNotConnected: PostPickerError = {
	name: "INSTAGRAM_NOT_CONNECTED",
	httpCode: 422,
	message: "This creator does not have their Instagram connected",
	description:
		"Please have the creator connect their Instagram account to July and try again",
	type: "api",
};

export const FacebookNotConnected: PostPickerError = {
	name: "FACEBOOK_NOT_CONNECTED",
	httpCode: 422,
	message: "This creator does not have their Facebook connected",
	description:
		"Please have the creator connect their Facebook account to July and try again",
	type: "api",
};

export const InstagramPostNotFound: PostPickerError = {
	name: "INSTAGRAM_POST_NOT_FOUND",
	httpCode: 400,
	message: "The Instagram post was not found",
	description:
		"We could not find a post with that URL belonging to this creator.",
	type: "api",
};

export const InstagramPostThumbnailNotFound: PostPickerError = {
	name: "NO_INSTAGRAM_THUMBNAIL",
	httpCode: 400,
	message: "There was an error loading this post",
	description: "We could not load this post, please try another one.",
	type: "api",
};
