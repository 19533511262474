import {
	AgencyDeal,
	AgencyDealManager,
	AgencyDealPreview,
	AgencyDealsColumn,
	DealDeliverablesSchema,
	DealEntry,
	DealFile,
	DealTask,
	DealTaskStatusSchema,
} from "@withjuly/fabric";
import { immer } from "zustand/middleware/immer";
import { createStore } from "zustand/vanilla";

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type DealState = {
	deal: AgencyDeal;
	deals: AgencyDealPreview[];
	columns: AgencyDealsColumn[];
};

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type DealActions = {
	resetState: () => void;

	// Set Deal
	setDeal: (deal: AgencyDeal) => void;
	setDealUuid: (uuid: string) => void;

	// Set text inputs
	setTitle: (title: string) => void;
	setBrandName: (name: string) => void;
	setNotes: (notes: string) => void;

	// Tasks
	addTask: (task: DealTask) => void;
	updateTask: (task: DealTask) => void;
	deleteTask: (uuid: string) => void;

	// Entries
	addEntry: (entry: DealEntry) => void;
	updateEntry: (entry: DealEntry) => void;
	deleteEntry: (uuid: string) => void;

	// Files
	addFile: (file: DealFile) => void;
	deleteFile: (uuid: string) => void;

	// Deals
	setDeals: (deals: AgencyDealPreview[]) => void;
	addDeal: (deal: AgencyDealPreview) => void;
	updateDeal: (deal: AgencyDeal) => void;
	deleteDeal: (uuid: string) => void;

	// Temp Deal
	addTempDeal: (
		uuid: string,
		columnUuid: string,
		order: number,
		createdBy: string,
	) => void;

	// Columns
	setColumns: (columns: AgencyDealsColumn[]) => void;
	addColumn: (column: AgencyDealsColumn) => void;
	deleteColumn: (uuid: string) => void;
	updateColumn: (column: AgencyDealsColumn) => void;

	// Managers
	updateManagers: (managers: AgencyDealManager[]) => void;
	updateManagersForDeal: (
		managers: AgencyDealManager[],
		dealUuid: string,
	) => void;
};
export type DealStore = DealState & DealActions;

export const defaultDealState = {
	deal: {
		uuid: "",
		title: "",
		brandName: "",
		entries: [],
		tasks: [],
		notes: "",
		columnUuid: "",
		files: [],
		managers: [],
	},
	deals: [],
	columns: [],
};

export const createDealStore = (initState: DealState = defaultDealState) => {
	return createStore<DealStore>()(
		immer((set) => ({
			...initState,
			resetState: () => {
				set(() => defaultDealState);
			},
			setDeal: (deal) => {
				set((state) => {
					state.deal = deal;
				});
			},
			setDealUuid: (uuid) => {
				set((state) => {
					state.deal.uuid = uuid;
				});
			},
			setTitle: (title) => {
				set((state) => {
					state.deal.title = title;
				});
			},
			setBrandName: (name) => {
				set((state) => {
					state.deal.brandName = name;
				});
			},
			addTask: (task) => {
				set((state) => {
					state.deal.tasks.unshift(task);
				});
			},
			updateTask: (task) => {
				set((state) => {
					const updatedTasks = [...state.deal.tasks];
					const indexToEdit = updatedTasks.findIndex(
						(t) => t.uuid === task.uuid,
					);

					if (indexToEdit !== -1) {
						updatedTasks[indexToEdit] = task;
						updatedTasks.sort((a, b) =>
							a.status === DealTaskStatusSchema.Enum.incomplete &&
							b.status !== DealTaskStatusSchema.Enum.incomplete
								? -1
								: b.status === DealTaskStatusSchema.Enum.incomplete &&
									  a.status !== DealTaskStatusSchema.Enum.incomplete
									? 1
									: 0,
						);

						state.deal.tasks = updatedTasks;
					}
				});
			},
			deleteTask: (uuid) => {
				set((state) => {
					const indexToDelete = state.deal.tasks.findIndex(
						(task) => task.uuid === uuid,
					);
					if (indexToDelete !== -1) {
						state.deal.tasks.splice(indexToDelete, 1);
					}
				});
			},
			setNotes: (notes) => {
				set((state) => {
					state.deal.notes = notes;
				});
			},
			addEntry: (entry) => {
				set((state) => {
					state.deal.entries.unshift(entry);
				});
			},
			updateEntry: (entry) => {
				set((state) => {
					state.deal.entries = state.deal.entries.map((e) => {
						if (e.uuid === entry.uuid) {
							return entry;
						} else {
							return e;
						}
					});
				});
			},
			deleteEntry: (uuid) => {
				set((state) => {
					const indexToDelete = state.deal.entries.findIndex(
						(entry) => entry.uuid === uuid,
					);
					if (indexToDelete !== -1) {
						state.deal.entries.splice(indexToDelete, 1);
					}
				});
			},
			setDeals: (deals) => {
				set((state) => {
					state.deals = deals;
				});
			},
			addDeal: (deal) => {
				set((state) => {
					state.deals.push(deal);
				});
			},
			updateDeal: (deal) => {
				set((state) => {
					state.deals = state.deals.map((d) => {
						if (d.uuid === deal.uuid) {
							const creators = deal.entries.map((entry) => {
								return entry.talent?.displayName;
							});

							const deliverables = deal.entries.flatMap((entry) =>
								DealDeliverablesSchema.parse(entry.deliverables),
							);
							const totalAmount = deliverables.reduce((acc, item) => {
								return acc + item.amount;
							}, 0);
							const nextDueDate = deal.tasks[0]?.dueDate;

							return {
								uuid: deal.uuid,
								title: deal.title,
								columnUuid: deal.columnUuid,
								brandName: deal.brandName,
								creators: creators.join(", "),
								nextDueDate: nextDueDate ? new Date(nextDueDate) : undefined,
								allTasksCompleted:
									deal.tasks.length === 0
										? false
										: deal.tasks.every((task) => task.status === "complete"),
								deliverables: {
									count: deliverables.length,
									amount: totalAmount,
								},
								order: d.order,
								totalTasks: deal.tasks.length,
								createdBy: d.createdBy,
								managers: deal.managers,
							};
						} else {
							return d;
						}
					});
				});
			},
			deleteDeal: (uuid) => {
				set((state) => {
					state.deals.splice(
						state.deals.findIndex((deal) => deal.uuid === uuid),
						1,
					);
				});
			},
			addTempDeal: (uuid, columnUuid, order, createdBy) => {
				set((state) => {
					state.deals.push({
						uuid: uuid,
						title: "New Deal",
						columnUuid: columnUuid,
						brandName: "",
						creators: "",
						nextDueDate: undefined,
						allTasksCompleted: false,
						deliverables: {
							count: 0,
							amount: 0,
						},
						order: order,
						totalTasks: 0,
						createdBy: createdBy,
						managers: [],
					});
				});
			},
			setColumns: (columns) => {
				set((state) => {
					state.columns = columns;
				});
			},
			addColumn: (column) => {
				set((state) => {
					state.columns.push(column);
				});
			},
			deleteColumn: (uuid) => {
				set((state) => {
					state.columns.splice(
						state.columns.findIndex((col) => col.uuid === uuid),
						1,
					);
				});
			},
			updateColumn: (column) => {
				set((state) => {
					state.columns = state.columns.map((col) => {
						if (col.uuid === column.uuid) {
							return column;
						} else {
							return col;
						}
					});
				});
			},
			addFile: (file) => {
				set((state) => {
					state.deal.files.unshift(file);
				});
			},
			deleteFile: (uuid) => {
				set((state) => {
					state.deal.files.splice(
						state.deal.files.findIndex((file) => file.uuid === uuid),
						1,
					);
				});
			},
			updateManagers: (managers) => {
				set((state) => {
					state.deal.managers = managers;
				});
			},
			updateManagersForDeal: (managers, dealUuid) => {
				set((state) => {
					state.deals = state.deals.map((d) => {
						if (d.uuid === dealUuid) {
							return { ...d, managers: managers };
						} else {
							return d;
						}
					});
				});
			},
		})),
	);
};
