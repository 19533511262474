import { z } from "zod";
import { AccentColorSchema, AllThemesSchema } from "./agency";
import { Platform, PlatformPostsSchema } from "./profile";
import { ThemeSchema } from "./themes";

export const AgencyReportContentTypeSchema = z.enum([
	"Instagram Reel",
	"Instagram Post",
	"Instagram Story",
	"Facebook Post",
	"Facebook Reel",
	"TikTok",
	"YouTube Video",
	"YouTube Short",
	"Custom",
]);
export type AgencyReportContentType = z.infer<
	typeof AgencyReportContentTypeSchema
>;

export const CustomImageShape = z.enum(["vertical", "square", "horizontal"]);

export const AgencyReportContentPostDataStatsSchema = z.object({
	name: z.string(),
	displayTitle: z.string(),
	value: z.union([z.number(), z.string()]),
	enabled: z.boolean(),
	uuid: z.string().uuid().optional(),
});

export type AgencyReportContentPostDataStats = z.infer<
	typeof AgencyReportContentPostDataStatsSchema
>;

export const AgencyReportContentPostData = z.object({
	platform: z.enum([
		"facebook",
		"instagram",
		"tiktok",
		"twitch",
		"email",
		"youtube",
		"instagram-noauth",
		"youtube-noauth",
		"tiktok-noauth",
		"custom",
	]),
	type: AgencyReportContentTypeSchema.optional(),
	postUrl: z.string().url().optional(),
	thumbnailUrl: z.string().url().optional(),
	staticMediaUrl: z.string().url().optional(), // only relevant for instagram reels
	caption: z.string().optional(),
	postedAt: z.number().optional(),
	expiresAt: z.number().optional(),
	statsFetchedAt: z.number().optional(),
	videoId: z.string().optional(),
	stats: z.array(
		AgencyReportContentPostDataStatsSchema.extend({
			uuid: z.string().uuid().optional(),
		}),
	),
	base64: z.string().optional(),
	custom: z
		.object({
			type: z.string(),
			image: z.string().optional(),
			shape: CustomImageShape.optional(),
		})
		.optional(),
});

export type AgencyReportContentPostData = z.infer<
	typeof AgencyReportContentPostData
>;

// old schema needed when fetching stats from api (oath-service)
export const AgencyReportOldContentPostDataStatsSchema = z.object({
	likes: z.number().optional().nullable(),
	comments: z.number().optional().nullable(),
	views: z.number().optional().nullable(),
	replays: z.number().optional().nullable(),
	reach: z.number().optional().nullable(),
	impressions: z.number().optional().nullable(),
	follows: z.number().optional().nullable(),
	profileActivity: z.number().optional().nullable(),
	saves: z.number().optional().nullable(),
	shares: z.number().optional().nullable(),
	averageViewDuration: z.number().optional().nullable(),
	averageViewPercentage: z.number().optional().nullable(),
	engagementRate: z.number().optional().nullable(),
	plays: z.number().optional().nullable(),
	totalInteractions: z.number().optional().nullable(),
	totalReactions: z.number().optional().nullable(),
});

export type AgencyReportOldContentPostDataStats = z.infer<
	typeof AgencyReportOldContentPostDataStatsSchema
>;

//old schema needed when fetching stats from api (oath-service)
export const AgencyReportOldContentPostData = z.object({
	platform: z.nativeEnum(Platform),
	type: AgencyReportContentTypeSchema.optional(),
	postUrl: z.string().url(),
	thumbnailUrl: z.string().url().optional(),
	staticMediaUrl: z.string().url().optional(),
	caption: z.string().optional(),
	postedAt: z.number().optional(),
	expiresAt: z.number().optional(),
	statsFetchedAt: z.number().optional(),
	videoId: z.string().optional(),
	stats: AgencyReportOldContentPostDataStatsSchema,
});
export type AgencyReportOldContentPostData = z.infer<
	typeof AgencyReportOldContentPostData
>;

export const AgencyReportContentCommentSchema = z.object({
	uuid: z.string().uuid(),
	comment: z.string().max(1024),
});
export type AgencyReportContentComment = z.infer<
	typeof AgencyReportContentCommentSchema
>;

export const ContentSchema = z.object({
	uuid: z.string().uuid(),
});
export type Content = z.infer<typeof ContentSchema>;

export const AgencyReportContentSchema = z.object({
	uuid: z.string().uuid(),
	createdAt: z.date(),
	updatedAt: z.date(),
	customImage: z.string().optional(),

	content: ContentSchema.array().optional(),

	type: z.string(),
	order: z.number(),
	postUrl: z.string().url(),
	postData: AgencyReportContentPostData,
	additionalPostData: AgencyReportContentPostData.array().optional(),
	comments: AgencyReportContentCommentSchema.array(),
	creatorProfileUuid: z.string().uuid(),
	areCommentsEnabled: z.boolean(),
	isCaptionEnabled: z.boolean(),
});
export type AgencyReportContent = z.infer<typeof AgencyReportContentSchema>;

export const UpsertAgencyReportContentSchema = z.object({
	// Indicates this is an incomplete content and the server shouldn't persist it
	isPlaceholder: z.boolean().optional().default(false),

	content: ContentSchema.array().optional(),

	uuid: z.string().uuid(),
	type: AgencyReportContentTypeSchema,
	postUrl: z.string(),
	postData: AgencyReportContentPostData,
	additionalPostData: AgencyReportContentPostData.array().optional(),
	order: z.number(),
	comments: AgencyReportContentCommentSchema.array(),
	areCommentsEnabled: z.boolean(),
	isCaptionEnabled: z.boolean(),
	creatorProfileUuid: z.string().uuid(),
});
export type UpsertAgencyReportContent = z.infer<
	typeof UpsertAgencyReportContentSchema
>;

export const AddAgencyReportReferralSchema = z.object({
	reportUuid: z.string().uuid(),
	agencyCreatorUuid: z.string().uuid(),
});
export type AddAgencyReportReferralSchema = z.infer<
	typeof AddAgencyReportReferralSchema
>;

export const UpsertAgencyReportReferralSchema = z
	.object({
		uuid: z.string().uuid(),
		order: z.number(),
		creator: z
			.object({
				uuid: z.string().uuid(),
			})
			.passthrough(),
	})
	.passthrough();
export type UpsertAgencyReportReferral = z.infer<
	typeof UpsertAgencyReportReferralSchema
>;

export const AgencyReportCreatorFeedbackSchema = z.object({
	creatorProfileUuid: z.string().uuid(),
	feedback: z.string(),
});
export type AgencyReportCreatorFeedback = z.infer<
	typeof AgencyReportCreatorFeedbackSchema
>;
export const AgencyReportMetadataSchema = z.object({
	stats: z.array(AgencyReportContentPostDataStatsSchema).optional(),
});
export type AgencyReportMetadata = z.infer<typeof AgencyReportMetadataSchema>;

export const UpsertAgencyReportSchema = z.object({
	uuid: z.string().uuid().optional(),
	title: z.string(),
	subtitle: z.string(),
	brandName: z.string(),
	order: z.string().array().optional(),
	brandLogo: z
		.object({
			uuid: z.string().uuid(),
			url: z.string().url().optional(),
		})
		.optional(),
	content: UpsertAgencyReportContentSchema.array(),
	referrals: UpsertAgencyReportReferralSchema.array(),
	isPublished: z.boolean(),
	isSummaryEnabled: z.boolean(),
	areReferralsEnabled: z.boolean(),
	feedback: AgencyReportCreatorFeedbackSchema.array(),
	metadata: AgencyReportMetadataSchema,
});
export type UpsertAgencyReport = z.infer<typeof UpsertAgencyReportSchema>;

export const CreateAgencyReportSchema = z.object({
	title: z.string(),
	subtitle: z.string(),
	brandName: z.string(),
	brandLogo: z
		.object({
			uuid: z.string().uuid().optional(),
			url: z.string().url().optional(),
		})
		.optional(),
});
export type CreateAgencyReport = z.infer<typeof CreateAgencyReportSchema>;

export const AgencyReportPreviewSchema = z.object({
	uuid: z.string().uuid(),
	title: z.string(),
	updatedAt: z.date(),
	createdAt: z.date(),
	isPublished: z.boolean(),
	brandName: z.string(),
	slug: z.string(),
	creators: z.array(z.string()),
});
export type AgencyReportPreview = z.infer<typeof AgencyReportPreviewSchema>;

export const AgencyReportCreatorSchema = z.object({
	uuid: z.string().uuid(),
	firstName: z.string(),
	lastName: z.string(),
	profilePicture: z
		.object({
			uuid: z.string().uuid(),
			url: z.string().url(),
			base64: z.string().optional(),
		})
		.optional(),
	content: AgencyReportContentSchema.array(),
	platforms: z.array(z.string()),
});
export type AgencyReportCreator = z.infer<typeof AgencyReportCreatorSchema>;

export const AgencyReportReferralSchema = z.object({
	uuid: z.string().uuid(),
	createdAt: z.date(),
	updatedAt: z.date(),
	order: z.number(),

	creator: z.object({
		uuid: z.string().uuid(),
		firstName: z.string(),
		lastName: z.string(),
		displayName: z.string().nullable(),
		username: z.string().nullable(),

		profilePicture: z
			.object({
				uuid: z.string().uuid(),
				url: z.string().url(),
				base64: z.string().optional(),
			})
			.optional(),
		profiles: z.array(
			z.object({
				uuid: z.string().uuid(),
				platform: z.nativeEnum(Platform),
				username: z.string(),
				size: z.number(),
				recentPosts: PlatformPostsSchema,
			}),
		),
	}),
});
export type AgencyReportReferral = z.infer<typeof AgencyReportReferralSchema>;

export const AgencyReportSection = z.enum(["summary", "talent", "referrals"]);
export type AgencyReportSection = z.infer<typeof AgencyReportSection>;

export const AgencyReportSchema = z.object({
	uuid: z.string().uuid(),
	createdAt: z.date(),
	updatedAt: z.date(),
	title: z.string(),
	subtitle: z.string(),
	slug: z.string(),
	order: z.array(AgencyReportSection),
	brandName: z.string(),
	brandLogo: z
		.object({
			uuid: z.string().uuid(),
			url: z.string().url(),
			base64: z.string().optional(),
		})
		.optional(),
	isPublished: z.boolean(),
	isSummaryEnabled: z.boolean(),
	areReferralsEnabled: z.boolean(),
	agency: z.object({
		uuid: z.string().uuid(),
		name: z.string(),
		username: z.string(),
		theme: AllThemesSchema,
		accentColor: AccentColorSchema,
		logo: z
			.object({
				uuid: z.string().uuid(),
				url: z.string().url(),
				base64: z.string().optional(),
			})
			.optional(),
	}),
	creators: AgencyReportCreatorSchema.array(),
	feedback: AgencyReportCreatorFeedbackSchema.array(),
	referrals: AgencyReportReferralSchema.array(),
	customTheme: ThemeSchema.nullable(),
	metadata: AgencyReportMetadataSchema,
	description: z.string(),
	isSummaryDescriptionEnabled: z.boolean(),
	areSummaryStatsEnabled: z.boolean(),
});
export type AgencyReport = z.infer<typeof AgencyReportSchema>;

export const UpdateContentCommentSchema = z.object({
	contentUuid: z.string().uuid(),
	reportUuid: z.string().uuid(),
	comments: z.array(AgencyReportContentCommentSchema),
});

export const UpdateContentPostDataSchema = z.object({
	contentUuid: z.string().uuid(),
	reportUuid: z.string().uuid(),
	postData: AgencyReportContentPostData,
});

export const DeleteContentSchema = z.object({
	contentUuid: z.string().uuid(),
	reportUuid: z.string().uuid(),
});

export const DeleteCreatorSchema = z.object({
	creatorUuid: z.string().uuid(),
	reportUuid: z.string().uuid(),
});

export const UpdateContentCaptionEnabledSchema = z.object({
	contentUuid: z.string().uuid(),
	reportUuid: z.string().uuid(),
	enabled: z.boolean(),
});

export const AddReportContentSchema = z.object({
	reportUuid: z.string().uuid(),
	agencyCreatorUuid: z.string().uuid(),
	contentType: AgencyReportContentTypeSchema,
	postUrl: z.string().url(),
});
export type AddReportContent = z.infer<typeof AddReportContentSchema>;
