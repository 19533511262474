import {
	AccentColorSchema,
	AgencyReport,
	AllThemesSchema,
	AgencyReportContentPostDataStats,
	AgencyReportReferral,
	AgencyReportContentComment,
	AgencyReportContent,
	AgencyReportCreator,
	AgencyReportPreview,
	AgencyReportCreatorFeedback,
	AgencyReportSection,
} from "@withjuly/fabric";
import { immer } from "zustand/middleware/immer";
import { createStore } from "zustand/vanilla";

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type ReportState = {
	report: AgencyReport & { isPreview?: boolean };
	reports: AgencyReportPreview[];
};

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type ReportActions = {
	resetState: () => void;

	// Set Report
	setReport: (report: AgencyReport) => void;
	setPublished: (publish: boolean) => void;
	setOrder: (order: AgencyReportSection[]) => void;

	// Set Cover
	setTitle: (title: string) => void;
	setSubtitle: (subtitle: string) => void;
	setBrandName: (name: string) => void;
	setBrandLogo: (uuid: string, url: string) => void;
	setSlug: (slug: string) => void;

	// Set Summary
	setStats: (stats: AgencyReportContentPostDataStats[]) => void;
	setShowSummary: (show: boolean) => void;
	setShowSummaryStats: (show: boolean) => void;
	setShowSummaryDescription: (show: boolean) => void;
	setSummaryDescription: (description: string) => void;

	// Set Referrals
	addReferral: (referral: AgencyReportReferral) => void;
	removeReferral: (uuid: string) => void;
	setReferrals: (referrals: AgencyReportReferral[]) => void;
	setShowReferrals: (show: boolean) => void;

	// Talent
	setCreators: (creators: AgencyReportCreator[]) => void;
	updateContent: (content: AgencyReportContent) => void;
	setContent: (content: AgencyReportContent, creatorUuid: string) => void;
	removeContent: (contentUuid: string) => void;
	setContentOrder: (
		allContent: AgencyReportContent[],
		creatorUuid: string,
	) => void;
	setCommentsforContentAt: (
		uuid: string,
		comments: AgencyReportContentComment[],
	) => void;
	setStatsforContentAt: (
		uuid: string,
		stats: (AgencyReportContentPostDataStats & { uuid?: string })[],
	) => void;
	setShowCaptionforContentAt: (uuid: string, show: boolean) => void;
	addCreator: (creator: AgencyReportCreator) => void;
	removeCreator: (uuid: string) => void;
	addCustomStat: (
		contentUuid: string,
		stat: AgencyReportContentPostDataStats & { uuid: string },
	) => void;
	deleteCustomStat: (contentUuid: string, statUuid: string) => void;

	// Feedback
	addFeedback: (feedback: AgencyReportCreatorFeedback) => void;
	updateFeedback: (feedback: AgencyReportCreatorFeedback) => void;
	deleteFeedback: (creatorProfileUuid: string) => void;

	// All Reports
	setReports: (reports: AgencyReportPreview[]) => void;
	addReport: (report: AgencyReportPreview) => void;
	setReportTitle: (title: string, uuid: string) => void;
	setReportPublished: (publish: boolean, uuid: string) => void;
};

export type ReportStore = ReportState & ReportActions;

export const defaultReportState = {
	report: {
		isPreview: true,
		uuid: "",
		createdAt: new Date(),
		updatedAt: new Date(),
		title: "",
		subtitle: "",
		slug: "",
		order: [],
		brandName: "",
		brandLogo: {
			uuid: "",
			url: "",
			base64: "",
		},
		isPublished: false,
		isSummaryEnabled: true,
		areReferralsEnabled: true,
		content: [],
		agency: {
			uuid: "",
			name: "",
			username: "",
			theme: AllThemesSchema.Enum.july,
			accentColor: AccentColorSchema.Enum.dark,
			logo: {
				uuid: "",
				url: "",
				base64: "",
			},
		},
		creators: [],
		feedback: [],
		referrals: [],
		customTheme: null,
		metadata: {
			stats: [],
		},
		description: "",
		isSummaryDescriptionEnabled: true,
		areSummaryStatsEnabled: true,
	},
	reports: [],
};

export const createReportStore = (
	initState: ReportState = defaultReportState,
) => {
	return createStore<ReportStore>()(
		immer((set) => ({
			...initState,
			resetState: () => {
				set(() => defaultReportState);
			},
			setReport: (report) => {
				set((state) => {
					state.report = report;
				});
			},
			setPublished: (publish) => {
				set((state) => {
					state.report.isPublished = publish;
				});
			},
			setTitle: (title) => {
				set((state) => {
					state.report.title = title;
				});
			},
			setSubtitle: (subtitle) => {
				set((state) => {
					state.report.subtitle = subtitle;
				});
			},
			setBrandName: (name) => {
				set((state) => {
					state.report.brandName = name;
				});
			},
			setStats: (stats) => {
				set((state) => {
					state.report.metadata.stats = [...stats];
				});
			},
			setShowSummary: (show) => {
				set((state) => {
					state.report.isSummaryEnabled = show;
				});
			},
			setShowSummaryStats: (show) => {
				set((state) => {
					state.report.areSummaryStatsEnabled = show;
				});
			},
			setShowSummaryDescription: (show) => {
				set((state) => {
					state.report.isSummaryDescriptionEnabled = show;
				});
			},
			setSummaryDescription: (description) => {
				set((state) => {
					state.report.description = description;
				});
			},
			setShowReferrals: (show) => {
				set((state) => {
					state.report.areReferralsEnabled = show;
				});
			},
			addReferral: (referral) => {
				set((state) => {
					state.report.referrals.push(referral);
				});
			},
			removeReferral: (uuid) => {
				set((state) => {
					state.report.referrals = state.report.referrals.filter(
						(r) => r.uuid !== uuid,
					);
				});
			},
			setReferrals: (referrals) => {
				set((state) => {
					state.report.referrals = [...referrals];
				});
			},
			setOrder: (order) => {
				set((state) => {
					state.report.order = [...order];
				});
			},
			setCommentsforContentAt: (uuid, comments) => {
				set((state) => {
					const content = state.report.creators
						.flatMap((creator) => creator.content)
						.find((c) => c.uuid === uuid);
					if (content) {
						content.comments = [...comments];
					}
				});
			},
			setStatsforContentAt(uuid, stats) {
				set((state) => {
					const content = state.report.creators
						.flatMap((creator) => creator.content)
						.find((c) => c.uuid === uuid);
					if (content) {
						content.postData.stats = [...stats];
					}
				});
			},
			setContent: (content, creatorUuid: string) => {
				set((state) => {
					const creator = state.report.creators.find(
						(creator) => creator.uuid === creatorUuid,
					);

					if (creator) {
						creator.content.push(content);
					}
				});
			},
			setContentOrder: (content, creatorUuid: string) => {
				set((state) => {
					const creator = state.report.creators.find(
						(creator) => creator.uuid === creatorUuid,
					);

					if (creator) {
						creator.content = content;
					}
				});
			},
			setBrandLogo: (uuid, url) => {
				set((state) => {
					if (state.report.brandLogo) {
						state.report.brandLogo.uuid = uuid;
						state.report.brandLogo.url = url;
					} else {
						state.report.brandLogo = {
							uuid: uuid,
							url: url,
						};
					}
				});
			},
			setCreators: (creators) => {
				set((state) => {
					state.report.creators = [...creators];
				});
			},
			addCreator: (creator) => {
				set((state) => {
					state.report.creators.push(creator);
				});
			},
			removeCreator: (uuid) => {
				set((state) => {
					const creatorIndex = state.report.creators.findIndex(
						(creator) => creator.uuid === uuid,
					);

					if (creatorIndex === -1) {
						return;
					}

					state.report.creators.splice(creatorIndex, 1);
				});
			},
			setShowCaptionforContentAt: (uuid, show) => {
				set((state) => {
					const content = state.report.creators
						.flatMap((creator) => creator.content)
						.find((c) => c.uuid === uuid);
					if (content) {
						content.isCaptionEnabled = show;
					}
				});
			},
			addFeedback: (feedback) => {
				set((state) => {
					state.report.feedback.push(feedback);
				});
			},
			updateFeedback: (feedback) => {
				set((state) => {
					state.report.feedback = state.report.feedback.map((fb) => {
						if (fb.creatorProfileUuid === feedback.creatorProfileUuid) {
							return feedback;
						} else {
							return fb;
						}
					});
				});
			},

			deleteFeedback: (creatorProfileUuid) => {
				set((state) => {
					const existingIndex = state.report.feedback.findIndex(
						(f) => f.creatorProfileUuid === creatorProfileUuid,
					);

					if (existingIndex !== -1) {
						state.report.feedback.splice(existingIndex, 1);
					}
				});
			},

			// functions for all reports
			setReports: (reports) => {
				set((state) => {
					state.reports = reports;
				});
			},
			addReport: (report) => {
				set((state) => {
					state.reports.unshift(report);
				});
			},
			setReportTitle: (title, uuid) => {
				set((state) => {
					const report = state.reports.find((r) => r.uuid === uuid);
					if (report) {
						report.title = title;
					}
				});
			},
			setReportPublished: (publish, uuid) => {
				set((state) => {
					const report = state.reports.find((r) => r.uuid === uuid);
					if (report) {
						report.isPublished = publish;
					}
				});
			},
			setSlug: (slug) => {
				set((state) => {
					state.report.slug = slug;
				});
			},
			removeContent: (contentUuid) => {
				set((state) => {
					for (const creator of state.report.creators) {
						const contentIndex = creator.content.findIndex(
							(content) => content.uuid === contentUuid,
						);

						if (contentIndex !== -1) {
							creator.content.splice(contentIndex, 1);
							return;
						}
					}
				});
			},
			addCustomStat: (contentUuid, stat) => {
				set((state) => {
					state.report.creators.forEach((creator) => {
						creator.content.forEach((content) => {
							if (content.uuid === contentUuid) {
								const existingIndex = !stat.uuid
									? -1
									: content.postData.stats.findIndex(
											(s) => stat.uuid === s.uuid,
										);

								if (existingIndex !== -1) {
									content.postData.stats[existingIndex] = stat;
								} else {
									content.postData.stats.push(stat);
								}
							}
						});
					});
				});
			},
			updateContent: (content) => {
				set((state) => {
					const getCreatorAndContentIndex = () => {
						for (const creator of state.report.creators) {
							for (const index in creator.content) {
								if (creator.content[index]?.uuid === content.uuid) {
									const i = Number(index);
									return [creator, i] as const;
								}
							}
						}

						return [null, null] as const;
					};

					const [creator, index] = getCreatorAndContentIndex();

					if (!creator || typeof index !== "number") {
						return;
					}

					if (creator) {
						creator.content[index] = content;
					}
				});
			},
			deleteCustomStat: (contentUuid: string, statUuid: string) => {
				set((state) => {
					const creator = state.report.creators.find((creator) =>
						creator.content.some((content) => content.uuid === contentUuid),
					);

					if (!creator) {
						return;
					}

					const content = creator.content.find(
						(content) => content.uuid === contentUuid,
					);

					if (!content) {
						return;
					}

					const statIndex = content.postData.stats.findIndex(
						(stat) => stat.uuid === statUuid,
					);

					if (statIndex === -1) {
						return;
					}

					content.postData.stats.splice(statIndex, 1);
				});
			},
		})),
	);
};
