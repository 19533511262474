import { z } from "zod";

export const PaginationSchema = z.object({
	offset: z.number().min(0),
	limit: z.number().min(1).max(20),
});
export type Pagination = z.infer<typeof PaginationSchema>;

export const OffsetPaginatedSchema = z.object({
	offset: z.number(),
	size: z.number(),
	total: z.number(),
	sectionTotal: z.number().optional(),
});
export type PaginatedResults<T> = z.infer<typeof OffsetPaginatedSchema> & {
	items: T[];
};

export const TrpcPaginationSchema = z.object({
	cursor: z.number().min(0).default(0),
	limit: z.number().min(1).max(20),
});
export type TrpcPagination = z.infer<typeof TrpcPaginationSchema>;

export const DateStringSchema = z.preprocess((arg) => {
	if (typeof arg == "string" || arg instanceof Date) return new Date(arg);
}, z.date());

export const TagColorOptionsSchema = z.enum([
	"green",
	"red",
	"pink",
	"purple",
	"brand",
	"graphite",
	"orange",
]);

export type TagColorOptions = z.infer<typeof TagColorOptionsSchema>;
