import {
	FabricOneSheet,
	CreatorCardSection,
	FabricCreatorCardPost,
	FabricEntry,
	PlatformStats,
	FabricCreatorCardRate,
} from "@withjuly/fabric";
import { createStore } from "zustand/vanilla";

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type DeckState = { deck: FabricOneSheet; activeEntry?: string };

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type DeckActions = {
	resetState: () => void;

	// Set the deck
	setDeck: (deck: FabricOneSheet) => void;

	setActiveEntry: (uuid: string | undefined) => void;

	// Update header
	updateTitle: (title: string) => void;
	updateDescription: (description: string) => void;
	updateContactEmail: (email: string) => void;
	updateShowStatsOverview: (show: boolean) => void;
	updateBrandLogoUrl: (logoUrl: string) => void;
	setCardSectionVisibility: (
		section: CreatorCardSection,
		show: boolean,
	) => void;

	setSlug: (slug: string) => void;
	setIsPublished: (isPublished: boolean) => void;

	// Update entries
	setEntries: (entries: FabricEntry[]) => void;
	setTalentDescription: (uuid: string, description: string) => void;
	setTalentCoverPhoto: (uuid: string, photoUrl: string) => void;
	setCreatorCardPosts: (uuid: string, posts: FabricCreatorCardPost[]) => void;
	deleteCreatorCardPost: (uuid: string, postUrl: string) => void;
	updateEntriesOrder: (entries: DeckState["deck"]["entries"]) => void;
	deleteEntry: (uuid: string) => void;
	setCreatorCardPlatformStats: (stats: PlatformStats[], uuid: string) => void;
	setCreatorCardRates: (rates: FabricCreatorCardRate[], uuid: string) => void;
};

export type DeckStore = DeckState & DeckActions;

export const defaultInitState: DeckState = {
	deck: {
		uuid: "",
		createdAt: new Date(),
		updatedAt: new Date(),
		title: "",
		showAbout: true,
		showPartnerships: true,
		showRates: true,
		showStats: true,
		slug: "",
		description: "",
		isPublished: false,
		contactEmail: "",
		brandName: "",
		showStatsOverview: true,
		entries: [],
		agency: {
			username: "",
			name: "",
			email: "",
			theme: "",
			accentColor: "",
			logoUrl: "",
			currency: "USD",
		},
		customTheme: null,
	},
};

export const createDeckStore = (initState: DeckState = defaultInitState) => {
	return createStore<DeckStore>()((set) => ({
		...initState,
		resetState: () => {
			set(() => defaultInitState);
		},
		setDeck: (deck) => {
			set((state) => {
				const activeEntry =
					deck.uuid !== state.deck.uuid ? undefined : state.activeEntry;
				return { ...state, deck: deck, activeEntry };
			});
		},
		setActiveEntry: (uuid) => {
			set((state) => ({ ...state, activeEntry: uuid }));
		},
		updateTitle: (newTitle) => {
			set((state) => ({ ...state, deck: { ...state.deck, title: newTitle } }));
		},
		updateDescription: (newDescription) => {
			set((state) => ({
				...state,
				deck: { ...state.deck, description: newDescription },
			}));
		},
		updateContactEmail: (email) => {
			set((state) => ({
				...state,
				deck: { ...state.deck, contactEmail: email },
			}));
		},
		updateShowStatsOverview: (show) => {
			set((state) => ({
				...state,
				deck: { ...state.deck, showStatsOverview: show },
			}));
		},
		updateBrandLogoUrl: (logoUrl) => {
			const getBrandLogo = (state: DeckState) => {
				if (state.deck.brandLogo) {
					return {
						...state.deck.brandLogo,
						url: logoUrl,
					};
				} else {
					return {
						uuid: "",
						url: logoUrl,
					};
				}
			};
			set((state) => ({
				...state,
				deck: {
					...state.deck,
					brandLogo: getBrandLogo(state),
				},
			}));
		},
		setCardSectionVisibility: (section, show) => {
			const getData = () => {
				if (section === CreatorCardSection.enum.about) {
					return {
						showAbout: show,
					};
				} else if (section === CreatorCardSection.enum.partnerships) {
					return {
						showPartnerships: show,
					};
				} else if (section === CreatorCardSection.enum.rates) {
					return {
						showRates: show,
					};
				} else if (section === CreatorCardSection.enum.stats) {
					return {
						showStats: show,
					};
				} else {
					return {};
				}
			};

			set((state) => ({ ...state, deck: { ...state.deck, ...getData() } }));
		},
		setTalentDescription: (uuid, description) => {
			const getEntry = (state: DeckState) => {
				return state.deck.entries.find((entry) => entry.uuid === uuid);
			};

			const updateEntryDescription = (state: DeckState) => {
				const newEntry = getEntry(state);
				if (!newEntry) {
					return state;
				} else {
					newEntry.creatorCard.description = description;

					const entries = state.deck.entries.map((entry) => {
						if (entry.uuid === uuid) {
							return newEntry;
						} else {
							return entry;
						}
					});

					return {
						...state,
						deck: {
							...state.deck,
							entries,
						},
					};
				}
			};

			set((state) => updateEntryDescription(state));
		},
		setTalentCoverPhoto: (uuid, photoUrl) => {
			const getEntry = (state: DeckState) => {
				return state.deck.entries.find((entry) => entry.uuid === uuid);
			};

			const updateEntryCoverPhoto = (state: DeckState) => {
				const newEntry = getEntry(state);
				if (!newEntry) {
					return state;
				} else {
					newEntry.creatorCard.profilePictureUrl = photoUrl;

					const entries = state.deck.entries.map((entry) => {
						if (entry.uuid === uuid) {
							return newEntry;
						} else {
							return entry;
						}
					});

					return {
						...state,
						deck: {
							...state.deck,
							entries,
						},
					};
				}
			};

			set((state) => updateEntryCoverPhoto(state));
		},
		setCreatorCardPosts: (uuid, posts) => {
			set((state) => {
				const entries = state.deck.entries.map((entry) => {
					if (entry.creatorCard.uuid === uuid) {
						const newCreatorCard = { ...entry.creatorCard };
						newCreatorCard.highlightedPosts = posts;
						newCreatorCard.isShowingDefaultPosts = false;
						entry.creatorCard = newCreatorCard;
						return entry;
					} else {
						return entry;
					}
				});

				return { ...state, deck: { ...state.deck, entries } };
			});
		},
		deleteCreatorCardPost: (uuid, postUrl) => {
			set((state) => {
				const entries = state.deck.entries.map((entry) => {
					if (entry.creatorCard.uuid === uuid) {
						const newCreatorCard = { ...entry.creatorCard };
						const posts = newCreatorCard.highlightedPosts.filter(
							(post) => post.postUrl !== postUrl,
						);
						newCreatorCard.highlightedPosts = posts;
						newCreatorCard.isShowingDefaultPosts = posts.length === 0;

						return { ...entry, creatorCard: newCreatorCard };
					} else {
						return entry;
					}
				});

				return { ...state, deck: { ...state.deck, entries } };
			});
		},
		updateEntriesOrder: (entries) => {
			set((state) => {
				return { ...state, deck: { ...state.deck, entries } };
			});
		},
		setSlug: (slug) => {
			set((state) => ({ ...state, deck: { ...state.deck, slug } }));
		},
		setIsPublished: (isPublished) => {
			set((state) => {
				return { ...state, deck: { ...state.deck, isPublished } };
			});
		},
		deleteEntry: (uuid) => {
			set((state) => {
				const newEntries = state.deck.entries.filter(
					(entry) => entry.uuid !== uuid,
				);
				return {
					...state,
					activeEntry: undefined,
					deck: { ...state.deck, entries: newEntries },
				};
			});
		},
		setEntries: (entries) => {
			set((state) => ({ ...state, deck: { ...state.deck, entries } }));
		},

		setCreatorCardPlatformStats: (stats, uuid) => {
			set((state) => {
				const entryIndex = state.deck.entries.findIndex(
					(entry) => entry.uuid === uuid,
				);

				const entry = state.deck.entries[entryIndex];

				const newEntries = [...state.deck.entries];
				if (entry) {
					newEntries.splice(entryIndex, 1, {
						...entry,
						creatorCard: {
							...entry.creatorCard,
							platformStats: stats,
						},
					});
				} else {
					return state;
				}

				return {
					...state,
					deck: { ...state.deck, entries: newEntries },
				};
			});
		},

		setCreatorCardRates: (rates, uuid) => {
			set((state) => {
				const entryIndex = state.deck.entries.findIndex(
					(entry) => entry.uuid === uuid,
				);
				const entry = state.deck.entries[entryIndex];
				const newEntries = [...state.deck.entries];
				if (entryIndex !== -1 && entry) {
					newEntries.splice(entryIndex, 1, {
						...entry,
						creatorCard: {
							...entry.creatorCard,
							rates: rates,
						},
					});
				} else {
					return state;
				}

				return {
					...state,
					deck: { ...state.deck, entries: newEntries },
				};
			});
		},
	}));
};
