import { z } from "zod";
import { TagColorOptionsSchema } from "./utils";

export const DealFilterSchema = z.enum(["createdBy", "creatorIs"]);
export type DealFilter = z.infer<typeof DealFilterSchema>;

export const DealTaskStatusSchema = z.enum(["complete", "incomplete"]);
export type DealTaskStatus = z.infer<typeof DealTaskStatusSchema>;

export const DealDeliverableSchema = z.object({
	title: z.string().min(2),
	amount: z.number(),
});

export type DealDeliverable = z.infer<typeof DealDeliverableSchema>;

export const DealDeliverablesSchema = z.array(DealDeliverableSchema);
export type DealDeliverables = z.infer<typeof DealDeliverablesSchema>;

export const DealEntrySchema = z.object({
	uuid: z.string().uuid(),
	talent: z
		.object({
			uuid: z.string().uuid(),
			creatorProfileUuid: z.string().uuid(),
			displayName: z.string(),
			profilePictureUrl: z.string().optional(),
		})
		.optional(),
	deliverables: DealDeliverablesSchema,
});
export type DealEntry = z.infer<typeof DealEntrySchema>;

export const DealTaskSchema = z.object({
	uuid: z.string().uuid(),
	title: z.string().min(1),
	status: DealTaskStatusSchema,
	dueDate: z.coerce.date().optional(),
});
export type DealTask = z.infer<typeof DealTaskSchema>;

export const DealTasksSchema = z.array(DealTaskSchema);
export type DealTasks = z.infer<typeof DealTasksSchema>;

export const DealFileSchema = z.object({
	uuid: z.string().uuid(),
	fileUrl: z.string().url().optional(),
	name: z.string(),
	createdAt: z.coerce.date().optional(),
	type: z.string(),
});
export type DealFile = z.infer<typeof DealFileSchema>;

export const AgencyDealManagerSchema = z.object({
	uuid: z.string().uuid(),
	name: z.string(),
	profilePictureUrl: z.string().optional(),
});
export type AgencyDealManager = z.infer<typeof AgencyDealManagerSchema>;

export const AgencyDealSchema = z.object({
	uuid: z.string().uuid(),
	title: z.string().min(1),
	brandName: z.string(),
	entries: z.array(DealEntrySchema),
	columnUuid: z.string().uuid(),
	tasks: z.array(DealTaskSchema),
	notes: z.string().optional(),
	files: z.array(DealFileSchema),
	managers: AgencyDealManagerSchema.array(),
});

export type AgencyDeal = z.infer<typeof AgencyDealSchema>;

export const AgencyDealPreviewSchema = z.object({
	uuid: z.string().uuid(),
	title: z.string().min(1),
	brandName: z.string(),
	columnUuid: z.string().uuid(),
	creators: z.string(),
	nextDueDate: z.coerce.date().optional(),
	deliverables: z.object({
		count: z.number(),
		amount: z.number(),
	}),
	allTasksCompleted: z.boolean(),
	totalTasks: z.number(),
	order: z.number(),
	createdBy: z.string().uuid(),
	managers: AgencyDealManagerSchema.array(),
});

export type AgencyDealPreview = z.infer<typeof AgencyDealPreviewSchema>;

export const AgencyDealFilterSchema = z.object({
	uuid: z.string(),
	createdByUuid: z.string().optional(),
	managerUuid: z.string(),
});

export type AgencyDealFilter = z.infer<typeof AgencyDealFilterSchema>;

export const AgencyDealsColumnSchema = z.object({
	uuid: z.string().uuid(),
	order: z.number(),
	title: z.string(),
	tagColor: TagColorOptionsSchema,
});
export type AgencyDealsColumn = z.infer<typeof AgencyDealsColumnSchema>;
