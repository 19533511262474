import React, { ReactNode } from "react";
import * as Switch from "@radix-ui/react-switch";
import { cx } from "../classnames";
import { Label } from "./Label";

interface ToggleSwitchProps {
	id?: string;
	toggle: boolean;
	label?: ReactNode;
	onToggle: (toggle?: boolean) => void;
	size?: "sm" | "md";
	disabled?: boolean;
	className?: string;
}

export const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
	disabled = false,
	size = "sm",
	id,
	label,
	toggle,
	onToggle,
	className,
}) => {
	const swtch = (
		<Switch.Root
			id={id}
			className={cx(
				"border--stroke-primary flex h-[20px] min-h-[20px] w-[36px] min-w-[36px] shrink-0 items-center gap-[10px] rounded-full border bg-[#99C9FF29] px-0.5 transition-all disabled:opacity-30",
				size === "md" && "h-[24px] min-h-[24px] w-[42px] min-w-[42px]",
				"data-[state=checked]:enabled:bg-brand data-[state=checked]:enabled:border-brand",
				"data-[state=checked]:disabled:bg-slate-6",
			)}
			checked={toggle}
			disabled={disabled}
			onClick={(e) => e.stopPropagation()}
			onCheckedChange={onToggle}
		>
			<Switch.Thumb
				className={cx(
					"bg-white-100 block h-[15.5px] min-h-[15.5px] w-[15.5px] min-w-[15.5px] shrink-0 grow-0 rounded-full drop-shadow-[0_2px_4px_rgba(0,0,0,0,0.16)] transition-colors transition-transform delay-0 duration-100 ease-out data-[state=checked]:translate-x-full",
					size === "md" && "h-[18px] min-h-[18px] w-[18px] min-w-[18px]",
				)}
			/>
		</Switch.Root>
	);

	if (label) {
		return (
			<Label
				className={cx("text-paragraph-sm flex items-center gap-3", className)}
			>
				{swtch}
				{label}
			</Label>
		);
	}

	return swtch;
};
