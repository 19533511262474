import { z } from "zod";
import { OffsetPaginatedSchema } from "./utils";
import dayjs from "dayjs";

const dateSchema = z.preprocess((arg) => {
	if (typeof arg == "string" || arg instanceof Date) return new Date(arg);
}, z.date());

const phoneNumber = z
	.string()
	.regex(/^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/)
	.transform((arg) => {
		return arg
			.replaceAll("(", "")
			.replaceAll(")", "")
			.replaceAll("-", "")
			.replaceAll(" ", "");
	});

const ssn = z.preprocess((arg) => {
	if (typeof arg !== "string") return;
	return arg.replaceAll("-", "").replaceAll(" ", "");
}, z.string().min(1));

export const InvoiceStatusSchema = z.enum([
	"draft",
	"open",
	"paid",
	"void",
	"blocked",
]);
export type InvoiceStatus = z.infer<typeof InvoiceStatusSchema>;

export const DueDateTermsSchema = z.enum(["net30", "net60", "net90", "custom"]);
export type DueDateTerms = z.infer<typeof DueDateTermsSchema>;

export const InvoiceSectionSchema = z.enum([
	"all",
	"draft",
	"sent",
	"paid",
	"archived",
]);
export type InvoiceSection = z.infer<typeof InvoiceSectionSchema>;

export const PaymentsAnalyticsSchema = z.object({
	completedPaymentsAmount: z.number(),
	requestedPaymentsAmount: z.number(),
	pitchesSent: z.number(),
});
export type PaymentsAnalytics = z.infer<typeof PaymentsAnalyticsSchema>;

export const FastPayStatusSchema = z.enum([
	// Waiting for invoice receiver to approve the invoice contents
	"waiting for approval",
	// We have manually decided this invoice is not eligible for fast pay
	"not eligible",
	// We have manually decided this invoice is eligible for fast pay
	"eligible",
	// The user has decided not to accept fast pay after being prompted
	"declined",
	// The user has decided to accept fast pay after being prompted
	"accepted",
	// The user has manually requested FastPay on an invoice
	"requested",
]);
export type FastPayStatus = z.infer<typeof FastPayStatusSchema>;

export const DotsStatusSchema = z.enum([
	"phone_number_required",
	"verification_required",
	"verified",
]);
export type DotsStatus = z.infer<typeof DotsStatusSchema>;

export const PayoutMethodSchema = z.enum(["paypal", "venmo", "ach"]);
export type PayoutMethod = z.infer<typeof PayoutMethodSchema>;

export const DotsKycStatusSchema = z.enum(["required", "verified"]);
export type DotsKycStatus = z.infer<typeof DotsKycStatusSchema>;

export const PaymentsResponseSchema = z.object({
	dots: z.object({
		phoneNumber: z.string().optional(),
		status: DotsStatusSchema,
		kyc: DotsKycStatusSchema,
		ach: z
			.object({
				accountId: z.string(),
				name: z.string(),
				mask: z.string(),
			})
			.optional(),
		venmo: z.string().optional(),
		paypal: z.string().optional(),
		defaultPayoutMethod: PayoutMethodSchema.optional(),
	}),
	availableBalance: z.number(),
	// Balance in transit to payout account
	pendingBalance: z.number(),
	analytics: z.object({
		totalAmountReceived: z.number(),
		totalAmountOutstanding: z.number(),
	}),
});
export type PaymentsResponse = z.infer<typeof PaymentsResponseSchema>;

export const UpsertInvoiceItemSchema = z.object({
	deletedAt: dateSchema.optional(),
	uuid: z.string().optional(),
	name: z.string(),
	amount: z.number(),
});
export type UpsertInvoiceItem = z.infer<typeof UpsertInvoiceItemSchema>;

export const UpsertInvoiceFormItemSchema = UpsertInvoiceItemSchema.extend({
	amount: z.string(),
});
export type UpsertInvoiceFormItem = z.infer<typeof UpsertInvoiceFormItemSchema>;

export const StripeAchCreditDestinationSchema = z.object({
	routingNumber: z.string().optional(),
	accountNumber: z.string().optional(),
	bankName: z.string().optional(),
	swiftCode: z.string().optional(),
});
export type StripeAchCreditDestination = z.infer<
	typeof StripeAchCreditDestinationSchema
>;

export const InvoiceItemSchema = z.object({
	deletedAt: dateSchema.optional(),
	uuid: z.string(),
	name: z.string(),
	amount: z.number(),
});
export type InvoiceItem = z.infer<typeof InvoiceItemSchema>;

export const UpsertInvoiceSchema = z.object({
	uuid: z.string().optional(),
	dueDateTerms: DueDateTermsSchema,

	// This only needs to be set for invoices with a custom due date
	dueAt: dateSchema
		.refine(
			(date) =>
				dayjs(date).isAfter(new Date()) ||
				dayjs(date).isSame(new Date(), "day"),
			"Date must be in the future",
		)
		.optional(),

	sendImmediately: z.boolean().default(false),

	customer: z.object({
		name: z.string().min(1),
		email: z.string().email(),
	}),

	items: z.array(UpsertInvoiceItemSchema),

	sendReminderEmails: z.boolean().default(true),
});

export const UpsertInvoiceFormSchema = UpsertInvoiceSchema.extend({
	items: z.array(UpsertInvoiceFormItemSchema),
});

export type UpsertInvoice = z.infer<typeof UpsertInvoiceSchema>;
export type UpsertInvoiceFrom = z.infer<typeof UpsertInvoiceFormSchema>;

export const InvoiceSchema = z.object({
	uuid: z.string().uuid(),
	createdAt: dateSchema,
	updatedAt: dateSchema,
	deletedAt: dateSchema.optional(),

	sentAt: dateSchema.optional(),
	dueAt: dateSchema.optional(),
	payoutSentAt: dateSchema.optional(),

	dueDateTerms: DueDateTermsSchema,

	customer: z.object({
		name: z.string().min(1),
		email: z.string().email(),
	}),

	status: InvoiceStatusSchema,
	archived: z.boolean().default(false),
	fastPayStatus: FastPayStatusSchema,
	managementFeePercent: z.number().optional(),
	pdfUrl: z.string().url().optional(),

	cardClientSecret: z.string().optional(),
	achDebitClientSecret: z.string().optional(),
	achCreditDestination: StripeAchCreditDestinationSchema.optional(),

	// Credit cards have a high percent fee, which we pass on to brands
	totalWithCardFee: z.number().optional(),

	items: z.array(InvoiceItemSchema),

	sendReminderEmails: z.boolean().default(true),
});
export type Invoice = z.infer<typeof InvoiceSchema>;

export const ViewInvoiceSchema = InvoiceSchema.extend({
	isInvoiceCreator: z.boolean().optional(),
});
export type ViewInvoice = z.infer<typeof ViewInvoiceSchema>;

export const PaginatedInvoicesSchema = OffsetPaginatedSchema.extend({
	items: z.array(InvoiceSchema),
});
export type PaginatedInvoices = z.infer<typeof PaginatedInvoicesSchema>;

export const InvoiceApprovalRequestSchema = z.object({
	hasBeenApproved: z.boolean(),
});
export type InvoiceApprovalRequest = z.infer<
	typeof InvoiceApprovalRequestSchema
>;

export const InvoiceApprovalStatusSchema = z.object({
	isInvoiceCreator: z.boolean(),
	hasBeenApproved: z.boolean().optional(),

	invoicerName: z.string(),
	invoiceeName: z.string(),

	amount: z.number(),
	totalWithCardFee: z.number().optional(),
	firstItemName: z.string().optional(),
});
export type InvoiceApprovalStatus = z.infer<typeof InvoiceApprovalStatusSchema>;

export const WithdrawFundsRequestSchema = z.object({
	amount: z.number(),
});
export type WithdrawFundsRequest = z.infer<typeof WithdrawFundsRequestSchema>;

export const InvoiceDebuggerRequestSchema = z.object({
	achPaymentAmount: z.number().optional(),
});
export type InvoiceDebuggerRequest = z.infer<
	typeof InvoiceDebuggerRequestSchema
>;

export const SetFastPayStatusSchema = z.object({
	fastPayStatus: FastPayStatusSchema,
});
export type SetFastPayStatus = z.infer<typeof SetFastPayStatusSchema>;

export const RequestFastPaySchema = z.object({
	invoiceUuid: z.string().uuid(),
	contractUuid: z.string().uuid(),
});
export type RequestFastPay = z.infer<typeof RequestFastPaySchema>;

export const VerifyDotsRequestSchema = z.object({
	token: z.string(),
});
export type VerifyDotsRequest = z.infer<typeof VerifyDotsRequestSchema>;

export const CreateDotsAccountSchema = z.object({
	countryCode: z.string(),
	phoneNumber: phoneNumber,
});
export type CreateDotsAccount = z.infer<typeof CreateDotsAccountSchema>;

export const CreateDotsAccountFormSchema = CreateDotsAccountSchema.extend({
	termsAgree: z.boolean(),
});
export type CreateDotsAccountForm = z.infer<typeof CreateDotsAccountFormSchema>;

export const DotsKycInfoSchema = z.object({
	postcode: z.string().min(1),
	city: z.string().min(1),
	country: z.string().min(1),
	state: z.string().min(1),
	addressLine1: z.string().min(1),
	addressLine2: z.string().optional(),
	ssn: ssn,
});
export type DotsKycInfo = z.infer<typeof DotsKycInfoSchema>;

export const AddDotsPayoutMethodSchema = z.discriminatedUnion("payoutMethod", [
	z.object({
		payoutMethod: z.literal("paypal"),
		payoutId: z.string().email(),
		setDefault: z.boolean(),
	}),
	z.object({
		payoutMethod: z.literal("venmo"),
		payoutId: z.string().min(10),
		setDefault: z.boolean(),
	}),
	z.object({
		payoutMethod: z.literal("ach"),
		achRoutingNumber: z
			.string()
			.length(9, "Routing number must be 9 characters"),
		achAccountNumber: z.string().min(1),
		achAccountType: z.enum(["checking", "savings"]),
		setDefault: z.boolean(),
	}),
]);
export type AddDotsPayoutMethod = z.infer<typeof AddDotsPayoutMethodSchema>;

export const DotsPayoutRequestSchema = z.discriminatedUnion("payoutMethod", [
	z.object({
		payoutMethod: z.literal("paypal"),
		payoutId: z.string().email(),
		amount: z.number(),
	}),
	z.object({
		payoutMethod: z.literal("venmo"),
		payoutId: z.string(),
		amount: z.number(),
	}),
	z.object({
		payoutMethod: z.literal("ach"),
		achAccountId: z.string(),
		amount: z.number(),
	}),
]);
export type DotsPayoutRequest = z.infer<typeof DotsPayoutRequestSchema>;

export const CreatePaymentIntentSchema = z.object({
	amount: z.number(),
	fee_amount: z.number(),
	currency: z.literal("usd"),
	metadata: z.record(z.string(), z.string()).optional(),
});
export type CreatePaymentIntent = z.infer<typeof CreatePaymentIntentSchema>;
