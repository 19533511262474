import {
	JSXElementConstructor,
	PropsWithChildren,
	ReactNode,
	useState,
} from "react";
import { cx } from "../classnames";
import Link from "next/link";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { SignOut } from "@withjuly/julycons/bold";

interface RootProps {
	className?: string;
}

export const Root: React.FC<PropsWithChildren<RootProps>> = ({
	className,
	children,
}) => {
	const [isNavOpen, setIsNavOpen] = useState(() => false);
	return (
		<>
			<div
				className="fixed left-0 top-0 h-screen w-8 lg:hidden"
				onMouseEnter={() => setIsNavOpen(() => true)}
			></div>
			<div
				onMouseLeave={() => setIsNavOpen(() => false)}
				className={cx(
					"font-repro bg-surface-secondary w-[256px] min-w-[256px] max-w-[256px] flex-col items-start justify-between transition-opacity lg:flex",
					"border-stroke-secondary rounded-solis-lg fixed left-4 top-4 h-[calc(100vh-32px)] border",
					"lg:border-r-stroke-secondary lg:static lg:h-full lg:animate-none lg:rounded-none lg:border-0 lg:border-r",
					!isNavOpen &&
						"animate-slide-out-left flex -translate-x-[100%] transform opacity-0 lg:-translate-x-0 lg:opacity-100",
					isNavOpen && "animate-slide-in-left z-50 flex opacity-100",
					className,
				)}
			>
				{children}
			</div>
		</>
	);
};

interface SectionProps {
	title?: string;
}

export const Section: React.FC<PropsWithChildren<SectionProps>> = ({
	title,
	children,
}) => {
	return (
		<div className="flex w-full flex-col gap-1">
			{title !== undefined ? (
				<p className="text-overline-xs text-text-tertiary mb-1 px-4 uppercase">
					{title}
				</p>
			) : null}
			{children}
		</div>
	);
};

export const Links: React.FC<PropsWithChildren> = ({ children }) => {
	return (
		<div className="flex h-full w-full flex-col justify-start gap-8 p-4">
			{children}
		</div>
	);
};

interface PageProps {
	icon?: JSXElementConstructor<{ className?: string }>;
	selected?: boolean;
	href?: string;
	onClick?: () => void;
}

export const Page: React.FC<PropsWithChildren<PageProps>> = ({
	icon: Icon,
	selected,
	href,
	onClick,
	children,
}) => {
	const Wrapper =
		onClick !== undefined
			? ({
					className,
					children,
				}: {
					className: string;
					children: ReactNode;
					href: string;
				}) => (
					<button className={className} onMouseDown={onClick}>
						{children}
					</button>
				)
			: Link;

	return (
		<Wrapper
			href={href ?? ""}
			className={`${selected ? "text-text-primary bg-surface-hover-1" : " text-text-secondary"} text-button-sm hover:bg-surface-hover-1 transiton-all flex h-10 w-full items-center gap-3 rounded-full pl-4 pr-[10px] transition-all`}
		>
			{Icon ? <Icon className="h-4 w-4" /> : null}
			{children}
		</Wrapper>
	);
};

interface FooterProps {
	text?: string;
	subtext?: string;
	logoUrl?: string;
}

export const Footer: React.FC<PropsWithChildren<FooterProps>> = ({
	text,
	subtext,
	logoUrl,
	children,
}) => {
	return (
		<DropdownMenu.Root modal={false}>
			<DropdownMenu.Trigger asChild={true}>
				<div className="border-stroke-tertiary hover:bg-surface-hover-1 w-full cursor-pointer border-t px-5 py-4">
					<div className="relative flex">
						<div className="flex w-full flex-row items-center gap-4 rounded-lg ">
							{logoUrl ? (
								<img
									className="aspect-square h-10 w-10 rounded-md object-cover object-center"
									src={logoUrl}
									alt=""
								/>
							) : (
								<div className="flex h-10 w-10 items-center justify-center rounded-lg bg-gray-500">
									{subtext?.[0] ?? ""}
								</div>
							)}
							<div className="flex flex-col items-start">
								<p className="font-repro text-body-sm truncate text-left">
									{text}
								</p>
								<p className="font-repro text-paragraph-xs text-text-secondary">
									{subtext}
								</p>
							</div>
						</div>
					</div>
				</div>
			</DropdownMenu.Trigger>
			<DropdownMenu.Content
				sideOffset={16}
				className="rounded-solis-lg border-stroke-primary bg-surface-quaternary font-repro data-[state=open]:animate-dropdown-open data-[state=closed]:animate-dropdown-close ml-4 w-[260px] max-w-[260px] border-[0.5px] focus:outline-none focus:ring-0"
			>
				<div className="flex w-full flex-col gap-1 p-1">{children}</div>
				<div className="border-t-stroke-tertiary border-t p-1">
					<FooterItem
						variant="link"
						text="Sign out"
						icon={SignOut}
						href="/signout"
					/>
				</div>
			</DropdownMenu.Content>
		</DropdownMenu.Root>
	);
};

interface BaseFooterItemProps {
	priority?: boolean;
	icon?: JSXElementConstructor<{ className?: string }>;
	text: string;
}

interface LinkFooterItemProps extends BaseFooterItemProps {
	variant?: "link";
	href: string;
}

interface ButtonFooterItemProps extends BaseFooterItemProps {
	variant: "button";
	onClick: DropdownMenu.DropdownMenuItemProps["onClick"];
}

type FooterItemProps = LinkFooterItemProps | ButtonFooterItemProps;

export const FooterItem: React.FC<FooterItemProps> = (props) => {
	const { icon: Icon, text } = props;
	props;

	const className =
		"focus:bg-surface-hover-1 rounded-solis-md hover:bg-surface-hover-1 text-button-sm flex h-10 cursor-pointer items-center gap-3 pl-4 pr-3 transition-colors focus:outline-none focus:ring-0";

	return (
		<DropdownMenu.Item
			asChild={props.variant === "link"}
			className={props.variant === "button" ? className : undefined}
			onClick={(e) => {
				if (props.variant === "button") {
					props.onClick?.(e);
				}
			}}
		>
			{props.variant === "link" ? (
				<Link className={className} href={props.href}>
					{Icon ? <Icon /> : null}
					{text}
				</Link>
			) : (
				<>
					{Icon ? <Icon /> : null}
					{text}
				</>
			)}
		</DropdownMenu.Item>
	);
};
