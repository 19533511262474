import { z } from "zod";

// Subscription pricing per talent and manager type
export const TALENT_DIRECT_CONNECTION_PRICE = 6;
export const TALENT_NO_AUTH_PRICE = 12;
export const TALENT_NON_EXCLUSIVE_PRICE = 2;
export const MANAGER_PRICE = 8;

export const AgencyBillingStatusSchema = z.enum([
	"active",
	"incomplete",
	"incomplete_expired",
	"trialing",
	"past_due",
	"canceled",
	"unpaid",
]);
export type AgencyBillingStatus = z.infer<typeof AgencyBillingSchema>;

export const AgencyBillingSchema = z.object({
	status: AgencyBillingStatusSchema,
	cardLastFour: z.string().nullable(),
	expiration: z.string().nullable(),
	nextChargeDate: z.date().nullable(),
	nextChargeAmount: z.number().nullable(),
	costBreakdown: z.object({
		monthlyPrice: z.number(),
		talentDirectConnection: z.object({
			count: z.number(),
			price: z.number().default(TALENT_DIRECT_CONNECTION_PRICE),
		}),
		talentNoAuth: z.object({
			count: z.number(),
			price: z.number().default(TALENT_NO_AUTH_PRICE),
		}),
		talentNonExclusive: z.object({
			count: z.number(),
			price: z.number().default(TALENT_NON_EXCLUSIVE_PRICE),
		}),
		manager: z.object({
			count: z.number(),
			price: z.number().default(MANAGER_PRICE),
		}),
	}),
});
export type AgencyBilling = z.infer<typeof AgencyBillingSchema>;

export const BillingReceiptSchema = z.object({
	paidAt: z.date().nullable(),
	total: z.number(),
	status: z.enum(["draft", "open", "paid", "uncollectible", "void", "deleted"]),
	url: z.string().url().nullable().optional(),
});
export type BillingReceipt = z.infer<typeof BillingReceiptSchema>;

export const MostRecentReceiptsSchema = BillingReceiptSchema.array().nullable();
export type MostRecentReceipts = z.infer<typeof MostRecentReceiptsSchema>;
